<template>
  <div class="gateway industrial-park">
    <!-- header 头部 -->
    <gateway-header :bgAttr="'rgba(54, 89, 241, 1)'" />

    <div class="industrial-park-banner">
      <img :src="BannerImg" alt="" />
    </div>

    <div class="park-layout gateway-module">
      <div class="module-title">产业园空间布局</div>
      <div class="module-content">
        <div class="box-wrap">
          <div class="ls-box">
            <div
              :class="['ls-item', { active: ele.type === areaHover }]"
              v-for="ele in CyyArr"
              :key="ele.type"
            >
              <div class="tag-box" @mouseenter="mouse3(ele.type)">
                {{ ele.name }}
              </div>
              <div
                v-for="item in CyyDataArr"
                class="text-content"
                :key="item.id"
              >
                <i>{{ item.name }}:</i>
                <p>{{ item.content }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="map-park-layout map-content">
          <div id="map-canvas"></div>
        </div>
      </div>
    </div>
    <!-- 产业园入驻企业 -->
    <div class="enterprises-settled gateway-module">
      <div class="module-title">产业园入驻企业</div>
      <div class="module-content">
        <!-- tab栏导航部分 -->
        <div class="sub-nav-box">
          <li
            :class="['ls-item', { active: subNavAct === idx }]"
            v-for="(ele, idx) in subNavls"
            :key="idx"
            @mouseenter="mouse1(ele.type)"
          >
            {{ ele.txt }}
          </li>
        </div>
        <div class="main-content">
          <div class="left-nav">
            <div class="nav-box">
              <li
                v-for="(ele, idx) in companyls"
                :class="['ls-item', { active: idx === companyAct }]"
                :key="idx"
                @mouseenter="mouse2(ele.id, idx)"
              >
                <!-- companyAct = idx -->

                <p>{{ ele.companyName }}</p>
              </li>
            </div>
          </div>
          <div class="detail-content">
            <div class="text-detail">
              {{ qiyeIntroduce || '暂无介绍' }}
            </div>
            <div class="honors">
              <div class="title">企业荣誉</div>
              <div class="imgs">
                <img :src="qiyeUrl" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="vr-layout gateway-module">
      <div class="module-title">产业园全景飞航漫游</div>
      <div @click="VrJumpClick" class="module-content">
        <!-- <img src="../../assets/img/gateway/bg-banner-home.png" alt="" /> -->
        <iframe
          src="https://zhanyu-vr.uyu1027.com/?scene_id=84309729"
          width="100%"
          height="100%"
          frameborder="0"
        ></iframe>
      </div>
    </div>
    <gateway-footer style="background: #031236; color: #fff" />
    <ToTopperComponent />
  </div>
</template>
<script>
import {
  ZhanyuQiye,
  ZhanyuCyy,
  ZhanyuCyyBanner,
} from './modules/industrial-park'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'vue-awesome-swiper/node_modules/swiper/dist/css/swiper.css'

import GatewayHeader from '../../components/gateway-header.vue'
import GatewayFooter from '../../components/gateway-footer.vue'
import ToTopperComponent from '../../components/to-topper-component.vue'
import { methods } from '../zhanyu/modules/methods'
import { loadBMap } from '../../utils/baidu-map/loadResources'
export default {
  name: 'industrial-park',
  data() {
    return {
      BannerImg: '',
      companyAct: 0,
      app: {},
      twelve_area_overlays: [],
      companyls: [],
      subNavAct: 0,
      // table维护数组
      subNavls: [
        { type: 1, txt: '苗种繁育' },
        { type: 2, txt: '深海养殖' },
        { type: 3, txt: '饲料产销' },
        { type: 4, txt: '加工仓储' },
        { type: 5, txt: '冷链物流' },
      ],
      // 产业园空间布局维护数组
      CyyArr: [
        { type: 1, name: '一核' },
        { type: 2, name: '双翼' },
        { type: 3, name: '三港' },
        { type: 4, name: '四心' },
        { type: 5, name: '十二片区' },
      ],
      // 产业园介绍数组对象
      CyyDataArr: [],
      areaHover: 1,
      lsHoverIdx: 5,
      oldtype: 0,
      // 企业介绍
      qiyeIntroduce: '',
      // 企业荣誉资质url
      qiyeUrl: '',
      ruzhuNum: '', //产业园入驻企业持久数
      bujuNum: '', //产业园空间布局持久数
    }
  },
  computed: {},
  methods: {
    // VR新建标签页
    VrJumpClick() {
      window.open('https://www.expoon.com/e/6dek18z89md/panorama', '_blank')
    },
    // table切换事件
    mouse1(idx) {
      this.subNavAct = idx - 1
      this.ZhanyuQiye(idx)
    },
    // 企业切换事件
    mouse2(id, idx) {
      this.companyAct = idx
      this.companyls.filter((item) => {
        if (item.id == id) {
          this.qiyeIntroduce = item.companyIntroduction
          // 目前对上企业Logo字段 日后修改成荣誉证书字段
          this.qiyeUrl = item.companyLogoUrl
        }
      })
    },
    // 产业园空间布局切换事件
    mouse3(type) {
      // 切换动画
      this.areaHover = type
      this.getCyyInfo(type)
    },
    fontHandle(num, unit) {
      return unit ? num + unit : num
    },
    clickItem(idx, type) {
      if (type == 'ruzhu') {
        this.ruzhuNum = idx
      } else if (type == 'buju') {
        this.bujuNum = idx
        console.log(this.bujuNum)
      }
    },
    ...methods,
    // 地图初始化后的主方法 **
    mapHandle() {
      var map = new BMapGL.Map('map-canvas')
      this.map = map
      map.setMapStyleV2({
        styleId: '0b09a3c6ed2d04d268eee6cb8f58ada7',
      })
      map.enableScrollWheelZoom(true) //开启鼠标滚轮缩放
      map.setMapType(BMAP_EARTH_MAP)

      this.seaArea('subpages')

      this.customeControlClass()

      this.editTxt2Map('subpages')

      // map.clearOverlays()
    },
    // 湛鱼企业请求事件
    async ZhanyuQiye(idx) {
      if (idx == this.oldtype) {
        return
      }
      let res = await ZhanyuQiye(idx)
      let { data } = res
      this.companyls = data
      let id = this.companyls[0].id
      this.mouse2(id, 0)
      this.oldtype = idx
    },
    // 产业园空间布局请求事件
    async getCyyInfo(type) {
      let res = await ZhanyuCyy(type)
      let { data } = res
      this.CyyDataArr = data
    },
    // 初始化产业园Banner图
    async getBannerImg() {
      let res = await ZhanyuCyyBanner()
      let { data } = res
      this.BannerImg = data[0].url
    },
  },
  components: {
    GatewayHeader,
    GatewayFooter,
    swiper,
    swiperSlide,
    ToTopperComponent,
  },
  beforeMount() {
    // 模板编译/挂载之前
  },
  mounted() {
    // 模板编译/挂载之后
    this.ZhanyuQiye(1)
    this.getCyyInfo(1)
    this.getBannerImg()
  },
  created() {
    this.app.fontHandle = this.fontHandle
    window.initBaiduMapScript = () => {
      this.mapHandle()
    }
    loadBMap('initBaiduMapScript')
  },
  updated() {
    // 组件更新之后
  },
  beforeDestroy() {
    // 组件销毁前调用
    // Tips：你确认删除XX吗？
  },
  destroyed() {
    // 组件销毁后调用
    // Tips：当前组件已被删除，清空相关内容
  },
  // 数据监听
  watch: {
    // keyName: {
    // 注意：当观察的数据为对象或数组时，curVal和oldVal是相等的，因为这两个形参指向的是同一个数据对象
    // deep: true,
    //handler (curVal, oldVal) {
    // console.log(curVal, oldVal)
    //}
    // }
  },
}
</script>
<style lang="less" scoped>
@import './industrial-park.less';
</style>
