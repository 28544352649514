/*
 * @Descripttion:
 * @Author: chenjie
 * @Date: 2021-10-25 10:22:05
 * @LastEditors: zcy
 * @LastEditTime: 2021-10-27 11:31:07
 */

import axios from '@/axios'
// const devUrl='http://gateway.uyu.com'

/**
 * @Author: zcy
 * @Date: 2021-10-27 11:31:10
 * @Descripttion:
 * @params { 初始化背景图片 }
 */

 export function initBannerImg(){
  return  axios({
    url:`/zhanjiang-portal/frontChamFish/getPicture`,
    methods:'get'
  })
}

/**
 * @Author: zcy
 * @Date: 2021-10-25 10:29:02
 * @Descripttion: 门户页面-获取湛鱼名优产品
 * @params {*}
 * @param {*} type
 */
export function ZhanyuChuangpin(){
  return  axios({
    url:`/zhanjiang-portal/frontChamFish/getFamousProducts`,
    methods:'get'
  })
}

/**
 * @Author: zcy
 * @Date: 2021-10-25 10:29:02
 * @Descripttion: 门户页面-获取湛鱼品牌资质
 * @params {*}
 * @param {*} type
 */
 export function ZhanyuZizi(){
  return  axios({
    url:`/zhanjiang-portal/frontChamFish/getBrandQualification`,
    methods:'get'
  })
}
