<!--
 * @Descripttion:提问
 * @Author: zcy
 * @Date: 2021-11-25 13:53:00
 * @LastEditors: zcy
 * @LastEditTime: 2021-12-06 15:34:02
-->
<template>
  <div class="problemForm img-dialog">
    <el-form
      ref="problemForm"
      :rules="rules"
      :model="problemForm"
      label-width="80px"
    >
      <el-form-item label="标题" prop="title">
        <el-input
          v-model="problemForm.title"
          placeholder="请输入文本标题"
        ></el-input>
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <el-input
          type="textarea"
          maxlength="300"
          show-word-limit
          :rows="20"
          placeholder="输入文本内容,不多于300字~"
          v-model="problemForm.content"
          >></el-input
        >
      </el-form-item>
      <el-form-item label="照片">
        <span class="UploadTitle">仅支持jpg/png文件，最多上传3张</span>
        <div class="UploadBox">
          <el-upload
            ref="el-upload"
            :file-list="fileList"
            :show-file-list="true"
            :http-request="UploadRequest"
            :on-exceed="handleExceedChange"
            :limit="3"
            :before-upload="beforeAvatarUpload"
            action="#"
            list-type="picture-card"
            :on-success="beforeSuccess"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button @click="formPrimartClick" type="primary">提交</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :visible.sync="CheckdialogVisible">
      <div class="img-dialog-header">
        <img
          @click="CheckdialogVisible = false"
          src="../../assets/img/gateway/expert-services/cancel.png"
          alt=""
        />
      </div>
      <div class="img-dialog-body">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { RegExpertTitle, RegExpertContent } from '@/utils/rules'

import {
  uploadImage,
  saveProblem,
} from '@/views/gateway/modules/expert-services/expert-services'
import Vue from 'vue'
import { Input, Form, FormItem, Upload, Button } from 'element-ui'
Vue.use(Input)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Upload)
Vue.use(Button)
export default {
  name: 'expertProblem',
  components: {},
  data() {
    return {
      dialogImageUrl: '',
      CheckdialogVisible: false,
      imgMaskTarget: false,
      // 是否需要图片
      needImg: false,
      // 判断图片是否上传完毕
      imageAddtarget: false,
      imageUrl: '',
      problemForm: {
        // 用户id
        userId: JSON.parse(localStorage.getItem('userInfo')).userInfo.id, //用户id
        title: '', //标题
        content: '', //内容
        urlOne: '', //图片1
        urlTwo: '', //图片2
        urlThree: '', //图片3
      },
      // 图片数组
      fileList: [],
      rules: {
        title: [{ required: true, validator: RegExpertTitle, trigger: 'blur' }],
        content: [
          { required: true, validator: RegExpertContent, trigger: 'blur' },
        ],
      },
    }
  },
  created() {},
  methods: {
    // 移除文件的钩子
    handleRemove(file, fileList) {
      const url = file.response.url
      if (this.problemForm.urlOne == url) {
        this.problemForm.urlOne = ''
      } else if (this.problemForm.urlTwo == url) {
        this.problemForm.urlTwo = ''
      } else if (this.problemForm.urlThree == url) {
        this.problemForm.urlThree = ''
      }
    },
    // 提交方法
    formPrimartClick() {
      this.$refs.problemForm.validate((valid) => {
        if (valid) {
          if (!this.imageAddtarget && this.needImg) {
            return this.$message.error('图片正在上传,请稍等...')
          }
          saveProblem(this.problemForm)
            .then((res) => {
              if (res.code !== '00000') return this.$message.error('提交失败')
              this.$message.success('提交成功,正在为您跳转页面')
              console.log(location.origin)
              location.href = `${location.origin}/expertServices`
            })
            .catch((err) => {
              let str = String(err)
              str = str.replace('Error:', '')
              return this.$message.error(str)
            })
        }
      })
    },
    // 自定义上传方法
    async UploadRequest(fileData) {
      const file = fileData.file
      const fileParams = new FormData()
      fileParams.append('file', file)
      let res = await uploadImage(fileParams)
      if (res.code !== '00000') return false
      return res.data
    },
    // 文件上传个数超出限制
    handleExceedChange() {
      return this.$message.error('最多只能上传三张图片')
    },
    // 文件上传之前钩子(限制文件大小和格式)
    beforeAvatarUpload(file, fileList) {
      const fileArr = ['image/jpeg', 'image/png']
      let target = fileArr.indexOf(file.type)
      if (target == -1) {
        this.$message.error('只能上传jpg和png格式的图片!')
        return false
      }
      this.needImg = true
      return true
    },
    // 点击列表中已上传文件的钩子
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.response.url
      this.CheckdialogVisible = true
    },
    // 上传成功回调
    beforeSuccess(response, file, fileList) {
      switch (fileList.length) {
        case 1:
          this.problemForm.urlOne = response.url
          if (this.problemForm.urlOne) {
            this.imageAddtarget = true
            this.needImg = false
          }
          break
        case 2:
          this.problemForm.urlTwo = response.url
          if (this.problemForm.urlTwo) {
            this.imageAddtarget = true
            this.needImg = false
          }
          break
        case 3:
          this.problemForm.urlThree = response.url
          if (this.problemForm.urlThree) {
            this.imageAddtarget = true
            this.needImg = false
          }
          this.$refs['el-upload'].$el.classList.add('no-update')
          break
        default:
          break
      }
    },
  },
}
</script>
<style lang="less" scoped>
::v-deep.problemForm {
  // 已上传图片遮罩层
  .el-message--error {
    top: 50%;
  }
  .el-input__inner:focus {
    border-color: #3659f1;
  }
  .el-input__inner:hover {
    border-color: #3659f1;
  }
  .el-textarea .el-textarea__inner:focus {
    border-color: #3659f1;
  }
  .el-textarea .el-textarea__inner:hover {
    border-color: #3659f1;
  }
  .el-button--primary {
    width: 339px;
    height: 56px;
    background: #3d62ff;
    border-radius: 6px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  .el-button--primary:hover {
    background: #3659f1;
  }
  .el-form-item {
    margin-bottom: 35px;

    .UploadTitle {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ff1c31;
    }
  }

  .el-form-item.is-required .el-form-item__label:after {
    content: '';
    display: block !important;
    text-align: center;
    line-height: 4px;
    width: 4px;
    top: 8px;
    height: 4px;
    left: -6px;
    background: #ff192f;
    border-radius: 50%;
    // display: none;
  }
  .el-input input {
    height: 38px;
    background: #f7f7f7;
    border-radius: 6px;
    border: 1px solid #dddddd;
  }

  // 清除element的label伪元素
  .el-form-item .el-form-item__label::before {
    display: none;
  }

  // 控制内容输入框的高度
  .el-textarea .el-textarea__inner {
    border-radius: 6px;
    background: #f7f7f7;
    height: 160px;
    border: 1px solid #dddddd;
  }
  .el-form-item .el-form-item__label {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #8e8c8c;
  }

  .no-update {
    .el-upload--picture-card {
      display: none !important;
    }
  }

  .UploadBox {
    // .el-upload-list--picture-card + .el-upload--picture-card .el-icon-plus {
    //   top: 25%;
    //   left: 39%;
    // }
    // 图片横向排列
    .el-upload-list--picture-card {
      display: flex;
    }

    .el-upload-list--picture-card .el-upload-list__item {
      width: 300px !important;
      height: 200px;
      border: 1px solid #dddddd;
    }

    .el-upload-list__item {
      transition: none !important;
    }

    // 上传按钮样式
    .el-upload-list--picture-card + .el-upload--picture-card {
      position: relative;
      width: 300px;
      height: 200px;
      background: #f7f7f7;
      border: 1px solid #dddddd;
      border-radius: 6px;
    }

    .el-upload-list__item-preview {
      margin-right: 30px;
      display: inline-block !important;
    }
    .el-upload--picture-card i {
      position: absolute !important;
      top: 42% !important;
      left: 47% !important;
    }

    .el-upload-list--picture-card + .el-upload--picture-card:hover {
      border: 1px solid #3d62ff;
    }

    div {
      display: flex;
    }
  }
}

::v-deep.img-dialog {
  .el-dialog .el-dialog__header {
    display: none;
  }

  .img-dialog-header {
    justify-content: flex-end;
    display: flex;

    img {
      cursor: pointer;
      margin: 10px 10px 10px 0px;
    }
  }

  .img-dialog-body {
    height: 0;
  }
}
</style>
